import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can now manage your weekly KPI email report on the `}<a parentName="p" {...{
        "href": "https://reports.dashbot.io/account/kpi"
      }}>{`KPI email preferences page`}</a>{`. We’ve upgraded the email report to include KPI’s for your goal’s report as well as the existing “basic” report for your bots.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      